<template>
	<div>
		<el-dialog :draggable="true" width="85%" top="5vh" v-model="outerVisible" @close="Reset" :title="title">
			<div class="dialog" element-loading-text="等待腾讯直播响应中..." v-loading="tableloading">
				<el-form ref="ruleForm" label-width="80px">
					<el-form-item label="直播间名称">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.name" placeholder="请输入直播间名称"></el-input>
					</el-form-item>

					<el-form-item label="封面图">
						<span class="xx">*</span>
						<div class="dialog-up">
							<!-- 预览图 -->
							<img v-if="ruleForm.cover_img" class="dialog-img" :src="ruleForm.cover_img" />
							<p v-if="ruleForm.cover_img" class="dele" @click="dele">删除</p>
							<el-upload accept=".jpg,.png" :headers="Headers" :action="ActionUrl"
								:on-success="handleAvatarSuccess">
								<div class="upload">
									<i class="icon iconfont icon-shangchuan"></i>
								</div>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="直播时间">
						<span class="xx">*</span>
						<el-date-picker @change="change" v-model="dates" type="datetimerange" range-separator="-"
							start-placeholder="请选择开始时间" end-placeholder="请选择结束时间">
						</el-date-picker>
					</el-form-item>

					<el-form-item label="主播昵称">
						<span class="xx"></span>
						<el-input clearable v-model="ruleForm.anchor_name" placeholder="请输入主播昵称"></el-input>
					</el-form-item>
					<el-form-item label="主播微信号">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.anchor_wechat" placeholder="请输入主播微信号"></el-input>
					</el-form-item>
					<el-form-item label="主播手机号">
						<span class="xx">*</span>
						<el-input clearable v-model="ruleForm.phone" placeholder="请输入主播手机号"></el-input>
					</el-form-item>
					<el-form-item label="关闭点赞">
						<span class="xx">*</span>
						<el-switch active-color="#00ad79" :active-value="1" :inactive-value="0"
							v-model="ruleForm.close_like"></el-switch>
					</el-form-item>
					<el-form-item label="关闭货架">
						<span class="xx">*</span>
						<el-switch active-color="#00ad79" :active-value="1" :inactive-value="0"
							v-model="ruleForm.close_goods"></el-switch>
					</el-form-item>
					<el-form-item label="关闭评论">
						<span class="xx">*</span>
						<el-switch active-color="#00ad79" :active-value="1" :inactive-value="0"
							v-model="ruleForm.close_comment"></el-switch>
					</el-form-item>
				</el-form>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button size="mini" @click="outerVisible = false">取消</el-button>
					<el-button size="mini" class="dialog-btn el-white" type="primary" @click="submitForm">确定
					</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/live";
	export default {
		data() {
			return {
				tableloading: false,
				dates: [],
				Headers: {
					token: ''
				},
				ActionUrl: '',
				outerVisible: false,
				title: '新增',
				list: [],
				ruleForm: {
					name: '',
					cover_img: '',
					share_img: '',
					start_time: '',
					end_time: '',
					anchor_name: '',
					anchor_wechat: '',
					phone: '',
					type: 0,
					screen_type: 0,
					close_like: 0,
					close_goods: 0,
					close_comment: 0
				}
			}
		},
		mounted() {
			// 上转图片url
			this.Headers.token = sessionStorage.getItem('token')
			this.Headers.appid = sessionStorage.getItem('appid')
			this.Headers.nonce = sessionStorage.getItem('nonce')
			this.Headers.timestamp = sessionStorage.getItem('timestamp')
			this.Headers.sign = sessionStorage.getItem('sign')

			this.ActionUrl = sessionStorage.getItem('baseURL') + '/Upload/FormUpload'
		},
		methods: {
			// 选择时间
			change(e) {
				if (e) {
					this.ruleForm.start_time = this.$Moment(e[0]).format('YYYY/MM/DD HH:mm:ss')
					this.ruleForm.end_time = this.$Moment(e[1]).format('YYYY/MM/DD HH:mm:ss')
				} else {
					this.ruleForm.start_time = ""
					this.ruleForm.end_time = ""
				}
			},
			// 删除图片
			dele() {
				this.ruleForm.cover_img = ''
				this.ruleForm.share_img = ''
			},
			// 上转图片
			handleAvatarSuccess(res, file) {
				this.ruleForm.cover_img = res.data.url
				this.ruleForm.share_img = res.data.url
			},
			// 图文详情
			Submitinput(e) {
				this.ruleForm.content = e
			},
			// 获取信息
			getinfo() {
				this.$http.post(api.info, {
						id: this.ruleForm.id
					})
					.then((res) => {
						if (res.code == 200) {
							this.ruleForm = res.data
							delete this.ruleForm.create_time
						}
					})
			},
			// 显示隐藏
			show(val, id) {
				this.title = val
				if (id) {
					this.ruleForm.id = id
					this.getinfo()
				}
				this.outerVisible = !this.outerVisible
			},
			// 清空数据
			Reset() {
				for (let key in this.ruleForm) {
					this.ruleForm[key] = ''
				}
				delete this.ruleForm.id
				delete this.ruleForm.delete_time
			},
			//新增/编辑
			submitForm() {
				if (this.ruleForm.name === '') {
					ElMessage({
						message: '请输入直播间名称',
						type: 'warning',
					})
				} else if (this.ruleForm.cover_img === '') {
					ElMessage({
						message: '请上传封面图',
						type: 'warning',
					})
				} else if (this.ruleForm.start_time === '') {
					ElMessage({
						message: '请选择直播时间',
						type: 'warning',
					})
				} else if (this.ruleForm.anchor_name === '') {
					ElMessage({
						message: '请输入主播昵称',
						type: 'warning',
					})
				} else if (this.ruleForm.anchor_wechat === '') {
					ElMessage({
						message: '请输入主播微信号',
						type: 'warning',
					})
				} else if (this.ruleForm.anchor_name === '') {
					ElMessage({
						message: '请输入主播昵称',
						type: 'warning',
					})
				} else if (this.ruleForm.phone === '') {
					ElMessage({
						message: '请输入主播手机号',
						type: 'warning',
					})
				} else {
					this.tableloading = true
					if (this.title == '新增') {
						this.add()
					} else {
						this.edit()
					}
				}
			},
			// 编辑
			edit() {
				let that = this
				this.$http.post(api.edit, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							this.tableloading = false
							ElMessage({
								type: 'success',
								message: '编辑成功',
							})
							this.$emit('SubmitForm')
							this.outerVisible = false
						}
					}).catch(err => {
						that.tableloading = false
					})
			},
			// 新增
			add() {
				let that = this
				this.$http.post(api.add, this.ruleForm)
					.then((res) => {
						if (res.code == 200) {
							ElMessage({
								type: 'success',
								message: '新增成功',
							})
							this.outerVisible = false
							// 刷新列表
							this.$emit('SubmitForm')
						}
					}).catch(err => {
						that.tableloading = false
					})
			}
		}
	}
</script>

<style scoped>
</style>
