<template>
	<div class="bg-Hight">
		<!-- 筛选 -->
		<div class="crud">
			<ul class="crud-list">
				<li>
					<el-button size="mini" @click="getList">刷新</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['liveadd']">
					<el-button @click="bindadd('新增')" color="#00ad79" style="color: white" class="el-white" size="mini">
						新增
					</el-button>
				</li>
				<li v-if="$store.state.villageId>0" v-permission="['livedele']">
					<el-button @click="alldele" class="el-white" size="mini" type="danger">删除</el-button>
				</li>
				<li>
					<el-select clearable v-model="form.live_status" placeholder="请选择状态">
						<el-option label="全部" value=""></el-option>
						<el-option label="直播中" :value="101"></el-option>
						<el-option label="未开始" :value="102"></el-option>
						<el-option label="已结束" :value="103"></el-option>
						<el-option label="禁播" :value="104"></el-option>
						<el-option label="暂停" :value="105"></el-option>
						<el-option label="异常" :value="106"></el-option>
						<el-option label="已过期" :value="107"></el-option>
					</el-select>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.anchor_name" placeholder="请输入主播昵称">
					</el-input>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.phone" placeholder="请输入主播手机号">
					</el-input>
				</li>
				<li>
					<el-input @clear="reset" type="text" clearable v-model="form.anchor_wechat" placeholder="请输入主播微信">
					</el-input>
				</li>
				<li>
					<el-button @click="search" color="#00ad79" class="el-white" style="color: white" size="mini">搜索
					</el-button>
				</li>
				<li>
					<el-button @click="reset" size="mini" class="el-white" type="danger">清空</el-button>
				</li>
			</ul>
		</div>
		<!-- 表格列表 -->
		<div class="table">
			<el-table element-loading-text="加载中..." v-loading="tableloading" :max-height="maxheight" border
				:data="tableData" @selection-change="handleSelectionChange">
				<el-table-column type="selection" width="80" />
				<el-table-column type="index" label="序号" width="80" />
				<el-table-column property="date" label="封面图">
					<template #default="scope">
						<el-avatar :size="30" :src="scope.row.cover_img">
						</el-avatar>
					</template>
				</el-table-column>
				<el-table-column property="name" label="直播间名称" />
				<el-table-column property="name" label="直播时间">
					<template #default="scope">
						<span>{{scope.row.start_time}}至{{scope.row.end_time}}</span>
					</template>
				</el-table-column>
				<el-table-column property="anchor_name" label="主播昵称" />
				<el-table-column property="anchor_wechat" label="主播微信号" />
				<el-table-column property="phone" label="主播手机号" />
				<el-table-column property="phone" label="状态">
					<template #default="scope">
						<span v-if="scope.row.live_status==101">直播中</span>
						<span v-if="scope.row.live_status==102">未开始</span>
						<span v-if="scope.row.live_status==103">已结束</span>
						<span v-if="scope.row.live_status==104">禁播</span>
						<span v-if="scope.row.live_status==105">暂停</span>
						<span v-if="scope.row.live_status==106">异常</span>
						<span v-if="scope.row.live_status==107">已过期</span>
					</template>
				</el-table-column>
				<el-table-column property="create_time" label="创建时间" />
				<el-table-column property="address" label="操作" width="200">
					<template #default="scope">
						<el-button v-if="scope.row.live_status==101||scope.row.live_status==102" v-permission="['livedao']" size="mini" @click="$router.push({path:'/importGood',query:{id:scope.row.id}})">导入商品</el-button>
						<el-button v-permission="['livedele']" size="mini" @click="dele(scope.row.id)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--分页 -->
			<div class="pages">
				<el-pagination background v-model="form.page" :page-sizes="[15, 20, 30, 40,50,100]"
					:page-size="form.limit" layout="sizes, prev, pager, next, jumper" :total="total"
					@size-change="handleSizeChange" @current-change="handleCurrentChange">
				</el-pagination>
			</div>
		</div>
		<!-- 新增/编辑弹窗 -->
		<add ref="add" @SubmitForm="getList"></add>
	</div>
</template>

<script>
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import {
		request,
		api
	} from "@/api/live";
	import add from './add.vue'
	export default {
		components: {
			add,
		},
		data() {
			return {
				maxheight: document.documentElement.clientHeight - 232 + 'px',
				total: 0,
				form: {
					phone: '',
					page: 1,
					limit: 15,
					anchor_name: '',
					anchor_wechat: '',
					live_status: ''
				},
				tableData: [],
				idlist: [],
			}
		},
		created() {
			this.getList()
		},
		methods: {
			// 新增
			bindadd(val, id) {
				this.$refs.add.show(val, id)
			},
			handleSizeChange(e) {
				this.form.limit = e
				this.getList()
			},
			// 分页
			handleCurrentChange(e) {
				this.form.page = e
				this.getList()
			},
			// 清空
			reset() {
				for (let key in this.form) {
					this.form[key] = ''
				}
				this.form.limit = 15
				this.form.page = 1
				this.form.is_duizhang = 1
				this.getList()
			},
			// 搜索
			search() {
				this.form.page = 1
				this.getList()
			},
			// 表格全选
			handleSelectionChange(e) {
				this.idlist = []
				e.forEach(item => {
					this.idlist.push(item.id)
				})
			},
			// 获取列表
			getList() {
				this.tableloading = true
				this.$http.post(api.lists, this.form)
					.then((res) => {
						if (res.code == 200) {
							this.tableData = res.data.list
							this.total = res.data.count
							this.tableloading = false
						}
					})
			},
			// 批量删除
			alldele() {
				if (this.idlist.length == 0) {
					ElMessage({
						message: '请选择需要删除数据',
						type: 'warning',
					})
				} else {
					ElMessageBox.confirm(
							'此操作将永久删除?',
							'提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning',
							}
						)
						.then(() => {
							this.$http.post(api.dele, {
									id: this.idlist.join(',')
								})
								.then((res) => {
									if (res.code == 200) {
										ElMessage({
											type: 'success',
											message: '删除成功',
										})
										this.form.page = 1
										this.getList()
									}
								})
						})
						.catch(() => {
							// 取消
						})
				}
			},
			// 删除
			dele(id) {
				ElMessageBox.confirm(
						'此操作将永久删除?',
						'提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						}
					)
					.then(() => {
						this.$http.post(api.dele, {
								id: id
							})
							.then((res) => {
								if (res.code == 200) {
									ElMessage({
										type: 'success',
										message: '删除成功',
									})
									this.form.page = 1
									this.getList()
								}
							})
					})
					.catch(() => {
						// 取消
					})
			}
		}
	}
</script>

<style scoped>
</style>
